import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import { GlobalStyles } from './global-styles'
import { ThemeProvider, makeStyles, Theme } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { theme } from '../helpers/theme'
import { Footer } from './footer'
import { classNames } from '../helpers/classnames'

const useStyles = makeStyles<Theme, { spacing?: number }>(theme => ({
  main: {
    padding: ({ spacing }) => `${theme.spacing(spacing ?? 6)} 0 ${theme.spacing(12)} 0`,
  },
}))

interface MainProps {
  spacing?: number
  className?: string
}

const Main: React.FC<MainProps> = ({ children, spacing, className }) => {
  const styles = useStyles({ spacing })
  return <main className={classNames(styles.main, className)}>{children}</main>
}

interface LayoutProps {
  mainProps?: MainProps
}

const Layout: React.FC<LayoutProps> = ({ children, mainProps }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          rel="stylesheet"
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      </Helmet>
      <Header />
      <GlobalStyles></GlobalStyles>
      <Main {...mainProps}>{children}</Main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
