import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { classNames } from '../helpers/classnames'

interface Props {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
  className?: string
}

export const Paper: React.FC<Props> = props => {
  const styles = useStyles(props)
  const Component = props.component || 'div'

  return (
    <Component className={classNames(styles.root, props.className)}>{props.children}</Component>
  )
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(5)} ${theme.spacing(4)}`,
    boxShadow: `0 0 0px 1px rgba(0,0,0,0.03), 0 1px 1px rgba(0,0,0,0.01), 0 2px 2px rgba(0,0,0,0.02), 0 4px 4px rgba(0,0,0,0.03), 0 8px 8px rgba(0,0,0,0.04)`,
    borderRadius: theme.shape.borderRadius,
  },
}))
