import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  makeStyles,
  TextField,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  Input,
} from '@material-ui/core'
import matchSorter from 'match-sorter'
// @ts-ignore
import { createTechnologyPath } from '../../shared/shared'
import SearchIcon from '@material-ui/icons/SearchTwoTone'

interface Query {
  api: {
    technologies: {
      name: string
      _id: string
      categories: { _id: string; name: string }[]
      tags: string[]
    }[]
  }
}

const Search: React.FC = () => {
  const [query, setQuery] = useState('')

  const {
    api: { technologies },
  } = useStaticQuery<Query>(graphql`
    query SearchQuery {
      api {
        technologies {
          _id
          name
          slug
          image
          localImage {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          }
          tags
          categories {
            _id
            name
          }
        }
      }
    }
  `)

  const results = query
    ? matchSorter(technologies, query, {
        keys: ['name', 'tags', item => item.categories.map(c => c.name)],
      })
    : []

  const styles = useStyles({})

  return (
    <div id="search-root">
      <noscript>
        <style>
          {`#search-root {
            visibility: hidden
          }`}
        </style>
      </noscript>
      <Autocomplete
        freeSolo
        id="search"
        className={styles.autoComplete}
        multiple={false}
        filterOptions={x => x}
        disableCloseOnSelect
        onChange={(_, value) => {
          if (value && value.slug) {
            navigate(createTechnologyPath(value.slug))
          }
        }}
        onInputChange={(e: any) => {
          setQuery(e.target.value)
        }}
        options={results}
        renderOption={option => {
          return (
            <ListItem component="div" style={{ padding: 0 }}>
              {/* <ListItemAvatar>
                <Avatar
                  variant="rounded"
                  classes={{ img: styles.avatar }}
                  src={option.localImage.childImageSharp.fixed.src}
                />
              </ListItemAvatar> */}
              <ListItemText
                primary={option.name}
                secondary={option.categories.map(c => c.name).join(', ')}
              />
            </ListItem>
          )
        }}
        renderInput={params => {
          return (
            <>
              <div className={styles.popperContainer} ref={params.InputProps.ref}></div>
              <div className={styles.inputWrap}>
                <SearchIcon className={styles.inputIcon} color="primary" />
                <input
                  {...params.inputProps}
                  placeholder="Keresés"
                  type="text"
                  id="search"
                  className={styles.input}
                />
              </div>
            </>
          )
        }}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  autoComplete: {
    '@media (min-width: 768px)': {
      position: 'relative',
    },
  },
  popperContainer: {
    position: 'absolute',
    left: 5,
    right: 5,
    bottom: 0,
  },
  root: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 5,
    padding: 0,
    border: `solid 1px ${theme.palette.grey[300]}`,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&$focused': {
      backgroundColor: '#f5f5f5',
    },
  },
  input: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    padding: ` ${theme.spacing(2)} calc(${theme.spacing(4)} + 1.25em)`,
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
  inputWrap: {
    position: 'relative',
  },
  inputIcon: {
    position: 'absolute',
    height: '100%',
    width: '1em',
    left: '0.25em',
  },
  focused: {},
  avatar: {
    objectFit: 'contain',
  },
}))

export default Search
