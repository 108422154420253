import * as React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { classNames } from '../helpers/classnames'

interface Props {
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
  spacing?: number
  className?: string
}

export const Stack: React.FC<Props> = props => {
  const styles = useStyles({ spacing: props.spacing })
  const Component = props.component || 'div'

  return (
    <Component className={classNames(styles.root, props.className)}>{props.children}</Component>
  )
}

const useStyles = makeStyles<Theme, { spacing?: number }>(theme => ({
  root: {
    '&>* + *': {
      marginTop: ({ spacing }) => theme.spacing(spacing ?? 6),
    },
  },
}))
