import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const MIN_FONT_SIZE = 15
const MAX_FONT_SIZE = 18
const MIN_VW = 320
const MAX_VW = 1920

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      fontSize: MIN_FONT_SIZE,
      [`@media screen and (min-width: ${MIN_VW}px)`]: {
        fontSize: `calc(${MIN_FONT_SIZE}px + ${MAX_FONT_SIZE -
          MIN_FONT_SIZE} * ((100vw - ${MIN_VW}px) / ${MAX_VW - MIN_VW}))`,
      },
      [`@media screen and (min-width: ${MAX_VW}px)`]: {
        fontSize: MAX_FONT_SIZE,
      },
    },
    body: {
      backgroundColor: '#fafafa',
      color: theme.palette.text.primary,
      fontFamily: "'Roboto', sans-serif",
    },
    '#gatsby-focus-wrapper': {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
  },
}))

export const GlobalStyles: React.FC = ({ children }) => {
  useStyles({})

  return <>{children}</>
}
