import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { classNames } from '../helpers/classnames'

interface Props {
  style?: React.CSSProperties
  className?: string
}

export const Container: React.FC<Props> = ({ children, style, className }) => {
  const styles = useStyles({})

  return (
    <div className={classNames(styles.root, className)} style={style}>
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `0 ${theme.spacing(3)}`,
    maxWidth: '55rem',
  },
}))
