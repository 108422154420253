import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import { Stack } from './stack'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
    boxShadow:
      '0px -3px 3px -2px rgba(0,0,0,0.05), 0px -3px 4px 0px rgba(0,0,0,0.02), 0px -1px 8px 0px rgba(0,0,0,0.01)',
    textAlign: 'center',
    marginTop: 'auto',
    '& a': {
      color: 'inherit',
    },
  },
}))

export const Footer: React.FC = props => {
  const styles = useStyles()

  return (
    <footer className={styles.root}>
      <Stack spacing={2}>
        <p>Sági Tamara – 2023</p>
        <div>
          <a href="mailto:tamara.sagi0325@gmail.com">tamara.sagi0325@gmail.com</a>
        </div>
      </Stack>
    </footer>
  )
}
