const createCategoryPath = slug => {
  return `/kategoria/${slug}`
}

const createSubcategoryPath = slug => {
  return `/alkategoria/${slug}`
}

const createTechnologyPath = slug => {
  return `/technologia/${slug}`
}

module.exports = {
  createCategoryPath,
  createSubcategoryPath,
  createTechnologyPath,
}
