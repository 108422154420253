import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { Paper } from '../components/paper'
import { makeStyles, Theme } from '@material-ui/core'
import { Inline } from '../components/inline'
import { Stack } from '../components/stack'
import { Container } from '../components/container'
import { TechonlogyList } from '../components/technology-list'
import Color from 'color'
import SEO from '../components/seo'
import { useScrollRatio } from '../hooks/useScrollRatio'
import { FixedImage } from './technology'

interface TagProps {
  checked: boolean
  name: string
  onChange: () => void
}

const srOnly: React.CSSProperties = {
  position: 'absolute',
  top: 'auto',
  left: -10000,
  width: 1,
  height: 1,
  overflow: 'hidden',
}

const useTagStyles = makeStyles<Theme, TagProps>((theme: Theme) => ({
  root: {
    display: 'inline-block',
    textDecoration: ({ checked }) => (checked ? 'none' : 'underline'),
    backgroundColor: ({ checked }) => (checked ? theme.palette.action.selected : 'transparent'),
    color: 'inherit',
    borderRadius: theme.shape.borderRadius,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    border: 'solid 1px transparent',
    '&:hover': {
      backgroundColor: ({ checked }) =>
        checked ? theme.palette.action.selected : theme.palette.action.hover,
    },
  },
  input: {
    ...srOnly,
    '&:focus + $root': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

const Tag: React.FC<TagProps> = props => {
  const styles = useTagStyles(props)
  return (
    <>
      <input
        className={styles.input}
        type="radio"
        name="subcategory"
        checked={props.checked}
        onChange={props.onChange}
        id={props.name}
      />
      <label htmlFor={props.name} className={styles.root}>
        {props.name}
      </label>
    </>
  )
}

const useSubcategoryMenuStyles = makeStyles<Theme, { color: string }>(theme => ({
  root: {
    textAlign: 'center',
    backgroundColor: ({ color }) => color,
    color: ({ color }) => theme.palette.getContrastText(color),
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 0,
    boxShadow: 'none',
  },
  fieldset: {
    border: 'none',
    '& > legend': {
      ...srOnly,
    },
  },
  tagList: {
    '& > *:first-of-type': {
      marginLeft: 'auto',
    },
    '& > *:last-of-type': {
      marginRight: 'auto',
    },
  },
  innerWrap: {
    position: 'relative',
    zIndex: 3,
  },
  bgImage: {
    maxWidth: '100%',
  },
  bgImageWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    maxWidth: '50%',
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      background: ({ color }) =>
        `linear-gradient(to left, ${color}, ${Color(color)
          .fade(0.07)
          .toString()})`,
      '@media screen and (min-width: 768px)': {
        background: ({ color }) =>
          `linear-gradient(to left, ${color}, ${Color(color)
            .fade(0.2)
            .toString()})`,
      },
    },
  },
  navWrap: {
    position: 'relative',
  },
  nav: {
    overflow: 'auto',
  },
}))

const useFadeStyles = makeStyles<Theme, { color: string; direction: 'left' | 'right' }>(theme => ({
  root: {
    position: 'absolute',
    width: 50,
    pointerEvents: 'none',
    top: 0,
    bottom: 0,
    left: ({ direction }) => (direction === 'left' ? 0 : undefined),
    right: ({ direction }) => (direction === 'right' ? 0 : undefined),
    transition: 'opacity 0.2s',
    background: ({ color, direction }) =>
      `linear-gradient(to ${direction}, ${Color(color)
        .fade(0.9)
        .toString()}, ${color})`,
  },
}))

interface SubcategoryMenuProps {
  category: {
    name: string
    slug: string
    color: string
    localImage: FixedImage
    subcategories: {
      _id: string
      slug: string
      name: string
    }[]
  }
  value: string | null
  onChange: (value: string | null) => void
}

export const SubcategoryMenu: React.FC<SubcategoryMenuProps> = ({ category, value, onChange }) => {
  const styles = useSubcategoryMenuStyles({ color: category.color })
  const fadeStylesLeft = useFadeStyles({
    color: category.color,
    direction: 'left',
  })
  const fadeStylesRight = useFadeStyles({
    color: category.color,
    direction: 'right',
  })
  const {
    ratio: { x },
    ref,
  } = useScrollRatio()

  const hasLeftFade = x === null ? false : x > 0.001
  const hasRightFade = x === null ? false : x < 0.999

  const image = category.localImage.childImageSharp.fixed

  return (
    <div>
      <noscript>
        <style>
          {`#nav-wrap {
              display: none
            }`}
        </style>
      </noscript>
      <Paper className={styles.root}>
        <div className={styles.bgImageWrap}>
          <img className={styles.bgImage} src={image.src} alt="" />
        </div>
        <Stack spacing={3} className={styles.innerWrap}>
          <h2>{category.name}</h2>
          {category.subcategories.length > 0 && (
            <div className={styles.navWrap} id="nav-wrap">
              <nav className={styles.nav} ref={ref}>
                <div
                  className={fadeStylesLeft.root}
                  style={{
                    opacity: hasLeftFade ? 1 : 0,
                  }}
                ></div>
                <fieldset className={styles.fieldset}>
                  <legend>Alkategóriák</legend>
                  <Inline className={styles.tagList}>
                    <Tag
                      name="Összes"
                      checked={value === null}
                      onChange={() => {
                        onChange(null)
                      }}
                    />
                    {category.subcategories.map(subcategory => (
                      <Tag
                        name={subcategory.name}
                        checked={subcategory.slug === value}
                        onChange={() => {
                          onChange(subcategory.slug)
                        }}
                      ></Tag>
                    ))}
                  </Inline>
                </fieldset>
                <div
                  className={fadeStylesRight.root}
                  style={{
                    opacity: hasRightFade ? 1 : 0,
                  }}
                ></div>
              </nav>
            </div>
          )}
        </Stack>
      </Paper>
    </div>
  )
}

const BREAK = 600

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '85ch',
    [`@media (max-width: ${BREAK}px)`]: {
      padding: 0,
    },
  },
  main: {
    [`@media (max-width: ${BREAK}px)`]: {
      padding: 0,
    },
  },
  stack: {
    [`@media (max-width: ${BREAK}px)`]: {
      '& > * + *': {
        marginTop: 0,
      },
    },
  },
}))

const Category: React.FC<Props> = ({ data }) => {
  const styles = useStyles()
  const category = data.api.category
  const [selectedSubcategory, setSelectedSubcategory] = React.useState<null | string>(() => {
    try {
      const searchParams = new URLSearchParams(window.location.search)
      const param = searchParams.get('alkategoria')
      if (category.subcategories.find(subcatgory => subcatgory.slug === param)) {
        return param
      }
      return null
    } catch (e) {
      return null
    }
  })

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('alkategoria', selectedSubcategory ?? 'ossszes')
    history.replaceState(null, '', `${window.location.pathname}?${searchParams}`)
  }, [selectedSubcategory])

  const technologies = selectedSubcategory
    ? category.technologies.filter(technology =>
        technology.subcategories.find(subcategory => subcategory.slug === selectedSubcategory)
      )
    : category.technologies

  return (
    <Layout mainProps={{ spacing: 0, className: styles.main }}>
      <SEO title={category.name}></SEO>
      <Stack className={styles.stack}>
        <SubcategoryMenu
          category={category}
          value={selectedSubcategory}
          onChange={value => {
            setSelectedSubcategory(value)
          }}
        ></SubcategoryMenu>
        <Container className={styles.container}>
          <TechonlogyList technologies={technologies}></TechonlogyList>
        </Container>
      </Stack>
    </Layout>
  )
}

export interface Technology {
  _id: string
  name: string
  slug: string
  localImage?: FixedImage
  shortDescription?: string
  subcategories: {
    _id: string
    slug: string
  }[]
}

interface Props {
  data: {
    api: {
      category: {
        _id: string
        name: string
        slug: string
        localImage: FixedImage
        color: string
        technologies: Technology[]
        subcategories: {
          _id: string
          slug: string
          name: string
        }[]
      }
    }
  }
}

export const query = graphql`
  query CategoryQuery($id: String!) {
    api {
      category(id: $id) {
        _id
        name
        slug
        image
        localImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
        color
        technologies {
          _id
          name
          slug
          image
          localImage {
            childImageSharp {
              fixed(width: 128) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          description
          shortDescription
          subcategories {
            _id
            slug
          }
        }
        subcategories {
          _id
          slug
          name
        }
      }
    }
  }
`

export default Category
