import * as React from 'react'
import { Technology } from '../templates/category'
import { Paper } from './paper'
// @ts-ignore
import { createTechnologyPath } from '../../shared/shared'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core'
import ArrowForward from '@material-ui/icons/ArrowForwardIos'
import { Stack } from './stack'

interface TechonlogyListProps {
  technologies: Technology[]
}

const useListStyles = makeStyles(theme => ({
  root: {
    gridGap: theme.spacing(3),
    listStyle: 'none',
    padding: 0,
  },
}))

export const TechonlogyList: React.FC<TechonlogyListProps> = ({ technologies }) => {
  const styles = useListStyles()

  return (
    <Paper className={styles.root}>
      {technologies.map(technology => (
        <TechnologyTile key={technology._id} technology={technology} />
      ))}
    </Paper>
  )
}

interface TechnologyTileProps {
  technology: Technology
}

const useTileStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    padding: `${theme.spacing(6)} ${theme.spacing(5)}`,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    transition: `background ${theme.transitions.duration.short}ms`,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  inner: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gridGap: theme.spacing(5),
  },
  image: {
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: theme.palette.grey[300],
  },
  description: {
    display: 'block',
    marginTop: theme.spacing(1.5),
    lineHeight: '1.5',
  },
  name: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    fontSize: '1.1em',
  },
  arrow: {
    color: '#676978',
  },
}))

const TechnologyTile: React.FC<TechnologyTileProps> = ({ technology }) => {
  const styles = useTileStyles()
  // const image = technology.localImage.childImageSharp.fixed

  return (
    <Link to={createTechnologyPath(technology.slug)} className={styles.root}>
      <div className={styles.inner}>
        <span>
          <span className={styles.name}>{technology.name}</span> <br />
          <span className={styles.description}>{technology.shortDescription}</span>
        </span>
        <ArrowForward className={styles.arrow} />
        {/* <img
          loading="lazy"
          className={styles.image}
          src={image.src}
          width={image.width}
          height={image.height}
        /> */}
      </div>
    </Link>
  )
}
