import { createMuiTheme } from '@material-ui/core'
import primary from '@material-ui/core/colors/deepPurple'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    custom: {
      shadow: string
    }
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    custom: {
      shadow: string
    }
  }
}

export const theme = createMuiTheme({
  spacing: factor => `${0.25 * factor}em`,
  typography: {
    fontFamily: '"Ubuntu", sans-serif',
  },
  custom: {
    shadow: `0 2px 1px rgba(0,0,0,0.05), 0 4px 2px rgba(0,0,0,0.05), 0 8px 4px rgba(0,0,0,0.05), 0 16px 8px rgba(0,0,0,0.05), 0 32px 16px rgba(0,0,0,0.05)`,
  },
  palette: {
    primary,
    text: {
      secondary: 'rgba(47, 49, 75, 0.87)',
    },
  },
})
