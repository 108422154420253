import { Link, graphql, useStaticQuery, navigateTo } from 'gatsby'
import React from 'react'
import Search from './search'
import { makeStyles, Button, IconButton } from '@material-ui/core'
import { Container } from './container'
import { useStaticTexts } from '../hooks/useStaticTexts'
import BackIcon from '@material-ui/icons/ArrowBackTwoTone'
import { Location } from '@reach/router'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow:
      '0 1px 1px rgba(0,0,0,0.01), 0 2px 2px rgba(0,0,0,0.01), 0 4px 4px rgba(0,0,0,0.01), 0 8px 8px rgba(0,0,0,0.01)',
    position: 'sticky' as any,
    top: 0,
    zIndex: theme.zIndex.appBar,
    borderBottom: 'solid 1px lightgray',
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.typography.body1.fontSize,
  },
  top: {
    backgroundColor: '#fff3e0',
    color: '#463e2f',
    padding: theme.spacing(3),
    textAlign: 'center',
    display: 'block',
    transition: `all ${theme.transitions.duration.short}ms`,
    '&:hover, &:focus': {
      backgroundColor: '#f3e7d4',
    },
  },
  inner: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    '@media screen and (min-width: 600px)': {
      gridTemplateColumns: '2fr 1fr',
    },
    alignItems: 'center',
    maxWidth: '75rem',
    margin: '0 auto',
    gridGap: theme.spacing(5),
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}))

const Header = () => {
  const styles = useStyles({})

  const [text, link] = useStaticTexts(['form-banner', 'form-banner-link'])

  return (
    <header className={styles.root}>
      {text && link && (
        <a href={link} className={styles.top}>
          {text}
        </a>
      )}
      <div className={styles.inner}>
        <div className={styles.titleWrap}>
          <Location>
            {({ location }) => (
              <>
                {location.pathname !== '/' && typeof window !== 'undefined' && window.prevLocation && (
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ marginRight: '1em' }}
                    onClick={() => {
                      window.history.back()
                    }}
                  >
                    <BackIcon />
                  </IconButton>
                )}
              </>
            )}
          </Location>
          <h1 className={styles.title}>
            <Link
              to="/"
              style={{
                color: 'inherit',
                textDecoration: `none`,
              }}
            >
              Digitális logopédia
            </Link>
          </h1>
        </div>
        <Search></Search>
      </div>
    </header>
  )
}
export default Header
