import * as React from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  component?: any
  className?: string
  children?: React.ReactNode
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& >* + *': {
      marginLeft: theme.spacing(3),
    },
  },
}))

export const Inline = React.forwardRef<unknown, Props>((props, ref) => {
  const styles = useStyles()

  const Component = props.component ?? 'div'

  return (
    <Component className={styles.root + ' ' + props.className} ref={ref as any}>
      {props.children}
    </Component>
  )
})
