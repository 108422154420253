import * as React from 'react'

type State = { x: number | null; y: number | null }

export function useScrollRatio() {
  const [ratio, setRatio] = React.useState<State>({ x: 0, y: 0 })
  const element = React.useRef<any>()

  const ref = React.useCallback(el => {
    const onScroll = () => {
      const x = el.scrollLeft / (el.scrollWidth - el.clientWidth)
      const y = el.scrollTop / (el.scrollHeight - el.offsetHeight)
      setRatio({ x: isNaN(x) ? null : x, y: isNaN(y) ? null : y })
    }

    if (el !== null) {
      element.current = el
      onScroll()
      el.addEventListener('scroll', onScroll)
      window.addEventListener('resize', onScroll)
    } else {
      if (element.current) {
        element.current.removeEventListener('scroll', onScroll)
        window.removeEventListener('resize', onScroll)
      }
    }
  }, [])

  return { ratio, ref }
}
