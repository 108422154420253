import { useStaticQuery, graphql } from 'gatsby'

interface Query {
  api: {
    texts: {
      key: string
      value: string
    }[]
  }
}

export function useStaticTexts(keys: string[]) {
  const {
    api: { texts },
  } = useStaticQuery<Query>(graphql`
    query getTexts2 {
      api {
        texts {
          key
          value
        }
      }
    }
  `)

  return keys.map(key => texts.find(text => text.key === key)?.value)
}
